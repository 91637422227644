// src/pages/About.tsx
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const PrivacyPolicy: React.FC = () => {

  const webUrl = process.env.REACT_APP_URL;

  return (
    <div>
      <video width="100%" autoPlay loop muted>
        <source src={`${webUrl}images/ec2.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography textAlign="left" variant="h6" gutterBottom>
        Privacy Policy
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="We respects our users privacy and recognizes the need to protect any personal information you share with us. We would like to assure you that we follow appropriate standards." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="By using our services or by otherwise giving us your information, you agree to the terms of this privacy policy." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Please review the following carefully so that you understand our privacy practices. We collect your name, mobile number, password and email id while booking request. These details are required to identify you as a unique user in order to provide you our services hassle free. We collect your travel details like current location, travel time, source address and destination location only for the booking requests you make with us. We also collect your feedback and ratings provided by you to improve our service offerings. We collect your mobile OS type and device id to uniquely identify your device in order to send you relevant push notifications. If you choose to use the tracking feature and SOS Alerts, we use the mobile numbers shared by you in your profile to send them your location details to track you. This is for your safety purpose only." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="We require the information to identify you as a user, improve our services and provide better service to our users. We keep this information for internal record keeping purposes only. We use your contact details to send you your trip related information to you as well as our service partners or associate. We may sometimes send you information about our new services, special offers or other information which we think you may find interesting to your email id or mobile number. We may also sometime contact you for your feedback or market research purposes via email or mobile number. We are committed to ensuring that your information is secure with us and have taken appropriate steps to protect the information you share with us. We have implemented technology and security features to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="By using our services through the contact centre, mobile app or website, you agree for us to collect any personal information necessary to provide you the requested service and you agree that such personal information may be disclosed to the service partners (who may be third parties) for those purpose or authorised governament agencies. We do not sell or rent any personal information to any third parties. We may change this policy from time to time by updating this section. Your continued use of our website, contact centre or mobile app and/or by continually making Bookings indicates your agreement with any future changes in this Privacy Policy. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen If there are any questions regarding this privacy policy you may write to us at greenrides.in. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the contact email address. We will promptly correct any information found to be incorrect." />
        </ListItem>
      </List>
    </div>
  );
};

export default PrivacyPolicy;
