import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
//import Constants from 'expo-constants';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const client = new ApolloClient({
  //link: new HttpLink({ uri: 'http://192.168.1.43:4000/graphql' }),
  //link: new HttpLink({ uri: 'http://4.213.42.204/graphql' }),
  // link: new HttpLink({ uri: 'http://192.168.0.114:4000/graphql'}),

  //link: new HttpLink({ uri: 'http://grpbackenddev.centralindia.cloudapp.azure.com/graphql' }),
  //link: new HttpLink({ uri: 'https://grpweb50.centralindia.cloudapp.azure.com/grpapi/graphql' }),
  link: new HttpLink({ uri: `${backendUrl}` }),

  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
