// src/pages/About.tsx
import React from 'react';
import { Typography, Button } from '@mui/material';

const About: React.FC = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" gutterBottom>
        This is the About page of our website.
      </Typography>
      <Button variant="contained" color="primary">
        Learn More
      </Button>
    </div>
  );
};

export default About;
