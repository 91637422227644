
// import { User } from "../models/User"
import { AuthDetails } from "../models/AuthDetails";
import client from './apolloClient';
import { VALIDATE_USER, SEND_OTP } from './queries';
// import * as SecureStore from 'expo-secure-store';
import { OtpDetails } from '../models/OtpDetails';
import { ApolloError } from '@apollo/client';

export const validateUser = async (phone: string, mpin: string): Promise<AuthDetails> => {
    console.log("validate user called in service")
    console.log( phone , mpin)
    try {
        const { data } = await client.query({
            query: VALIDATE_USER,
            variables: { phone, mpin },
        });

        console.log('Response data in userservice:', data);
        return data?.validateUser;
    } catch (error) {
        console.log('Error fetching validate user:', error);
        if (error instanceof ApolloError) {
            if (error.graphQLErrors.length > 0) {
                console.error('GraphQL Errors:', error.graphQLErrors);
            }
            if (error.networkError) {
                console.error('Network Error:', error.networkError);
            }
        } else {
            console.error('General Error:', error);
        }
        return { userId: "", authToken: "", created: "", message: "Error : API call issues!", phone: phone };
    }
}

export const sendOtp = async (phone: string): Promise<OtpDetails> => {
    console.log(`sendOtp ${phone}`);    
    try {
        const { data } = await client.query({
            query: SEND_OTP,
            variables: { phone },
        });
        return data?.sendOtp;
    } catch (error) {
        console.log('Error fetching sendotp:', error);
        return {message: "OTP is not valid!", status: "false" };
    }
}



