// import React from 'react';
// import './App.css';
// import { jsPDF } from 'jspdf';

// const App: React.FC = () => {

//     const generatePDF = () => {
//       const doc = new jsPDF();

//       // Add some text
//       doc.text("Hello world!", 10, 10);

//       // Save the PDF
//       doc.save("sample.pdf");
//     };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>Welcome to Green Rides Pune!</h1>
//         <p>Comming Soon ......</p>
//       </header>
//       <div>
//         <button onClick={generatePDF}>Generate PDF</button>
//       </div>
//     </div>
//   );
// }

// export default App;

// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';
import Invoice from './pages/Invoice';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NewBookingForm from './pages/NewBookingForm'
import Login from './pages/Login';
import ValidateOtpLoginPage from './pages/ValidateOtpLoginPage';
import UserCompletedRidesPage from './pages/UserCompletedRidesPage';
import UserNextRidesPage from './pages/UserNextRidesPage';
import UserBookingDetailsReadOnly from './pages/UserBookingDetailsReadOnlyPage';
import UserBookingDetails from './pages/UserBookingDetailsPage';


import ContactUs from './pages/ContactUs';
import { Container } from '@mui/material';
import SourceDestinationPage from './pages/SourceDestinationPage';
import StickyHeader from './components/StickyHeader';
import './App.css';
import Footer from './components/Footer';
import PDFExport from './pages/PDFExport';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <StickyHeader />
        <Container className="grpContainer">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/newbooking" element={<NewBookingForm />} />
            <Route path="/book-your-green-ride" element={<NewBookingForm />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            {/* <Route path="/invoice/:bookingId" element={<Invoice />} />
            <Route path="/quote" element={<SourceDestinationPage />} />
            <Route path="/exportpdf/:bookingId" element={<PDFExport />} /> */}
            {/* <Route path="/newbooking2" element={<NewBookingPage2 />} />
            <Route path="/newbooking3" element={<NewBookingPage3 />} /> */}
            {/* <Route path="/newbooking4" element={<NewBookingPage4 />} /> */}
            {/* <Route path="/login" element={<Login />} />
            <Route path="/validateotplogin" element={<ValidateOtpLoginPage />} />
            <Route path="/usercompletedrides" element={<UserCompletedRidesPage />} />
            <Route path="/usernextrides" element={<UserNextRidesPage />} />
            <Route path="/userBookingDetailsReadOnly" element={<UserBookingDetailsReadOnly />} />
            <Route path="/userBookingDetails" element={<UserBookingDetails />} /> */}


          </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
