import React, { useState, useEffect } from 'react';
import { Button, Container, Box, Typography, Grid, Divider, Alert } from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';
import { RequestBookingService } from "../components/services/RequestBookingService"
import { Person, Phone, CalendarToday } from '@mui/icons-material';
import SourceDestination from '../components/booking/common/SourceDestination'
import { dateFormat } from '../components/booking/components/common/dateUtils'
import CardImageRight from '../components/booking/common/CardImageRight'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../components/services/AppContext'
import PeopleIcon from '@mui/icons-material/People';
import LuggageIcon from '@mui/icons-material/Luggage';
import BackpackIcon from '@mui/icons-material/Backpack';
//import SuitcaseIcon from '@mui/icons-material/Suitcase';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import CheckroomIcon from '@mui/icons-material/Checkroom';

const libraries: ("places")[] = ["places"];

interface Form3Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBack: () => void;
  // handleSubmit: () => void;
  handleNext: () => void;
}

const NewBookingForm3: React.FC<Form3Props> = ({ formValues, handleInputChange, handleNext, handleBack, }) => {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Use environment variable for API key
    libraries: libraries
  });

  const navigate = useNavigate();


  const [duration, setDuration] = useState<string | null>(null);
  const [needCarrier, setNeedCarrier] = useState(false);
  const [carrierCharges, setCarrierCharges] = useState<number>(0);
  const [selectedExtraPickups, setSelectedExtraPickups] = useState<number>(0);
  const [extraWaiting, setExtraWaiting] = useState<number>(0);
  const [extraWaitingCharges, setExtraWaitingCharges] = useState<number>(0);
  const [extraKms, setExtraKms] = useState<number>(0);
  const [extraKmsCharges, setExtraKmsCharges] = useState<number>(0);
  const [extraPickup, setExtraPickup] = useState<number>(0);
  const [parking, setParking] = useState<number>(0);
  const [baseFare, setBaseFare] = useState<number>(3960);
  const [toll, setToll] = useState<number>(365)
  const [gst, setGst] = useState<number>(0);
  const [totalFare, setTotalFare] = useState<number>(4200);


  const bookingDateTime = `${formValues.bookingDate}T${formValues.bookingTime?.trimLeft()}`;

  useEffect(() => {
    calculateFare();
  }, []);



  const calculateFare = async () => {

    let localParking = 0;
    if (formValues.returnRide) {
      setBaseFare(6160);
      setToll(730)
    }


    if (formValues.source?.includes("Terminal 2") || formValues.source?.includes("T1, Navpada")) {
      localParking = 250;
    }

    let localextraKms = 0;

    if (formValues.distance !== null) {
      const distanceInt = formValues.distance;
      if (distanceInt && distanceInt > 160) {
        localextraKms = distanceInt - 160;
        setExtraKms(localextraKms);
      }
      else {
        setExtraKms(0);
      }
    }
    setExtraKmsCharges(localextraKms * 38);


    let extraPickupAmount = 0;
    const localSelectedExtraPickups = selectedExtraPickups;
    if (localSelectedExtraPickups > 0) {
      extraPickupAmount = 200 * localSelectedExtraPickups;
    }


    const amount = baseFare + toll + localParking + (localextraKms * 38) + extraPickupAmount + extraWaiting + carrierCharges;

    const gstInt = Math.ceil(amount * 0.05);

    setExtraPickup(extraPickupAmount);

    setParking(localParking);

    setGst(gstInt);
    // console.log("gst " + gst)
    const fare = amount + gstInt
    setTotalFare(fare);

  };




  if (!isLoaded) return <div>Loading...</div>;

  return (

    <Container maxWidth="sm" sx={{ mt: 5 }}>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <CalendarToday sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
              <Typography sx={{ mr: 1, mb: 2 }}>Booking Date: {dateFormat(bookingDateTime)}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PeopleIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
              <Typography sx={{ mr: 1, mb: 2 }}>Number of Passengers: {formValues.numberOfPassengers}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LuggageIcon sx={{ color: '#4caf50', mr: 1, mb: 2 }} />
              <Typography sx={{ mr: 1, mb: 2 }}>Number of Big Bags: {formValues.numberOfBigBags}</Typography>
            </Box>
            <Divider sx={{ mb: 1 }} />
          </Grid>

          {/* Travel Details */}
          <Grid item xs={12} sm={12} lg={12}>
            <SourceDestination source={formValues.source} destination={formValues.destination} />
          </Grid>
          <CardImageRight header={'Luxury SUV+'} text={'Electric BYD E6'} imageSource={require('../images/BYD1.png')} ></CardImageRight>
          {/* Estimated Fare */}
          <Grid>
          </Grid>
          {/* <CabFeatures></CabFeatures> */}
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={0.5}>
                {[
                  { label: 'Estimated Fare', value: `₹${RequestBookingService.getRequestBooking().booking.payment.totalFare}`, isEstimated: true },
                  { label: 'Base Fare', value: `₹${RequestBookingService.getRequestBooking().booking.payment.baseFare}` },
                  { label: "Extra Km Charges  " + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraKms}` + ' x ₹38 )', value: `₹${RequestBookingService.getRequestBooking().booking.payment.extraKmCharges}` },
                  { label: 'Extra Pickup', value: `₹${RequestBookingService.getRequestBooking().booking.payment.extraPickorDropCharges}` },
                  { label: 'GST (5%)', value: `₹${RequestBookingService.getRequestBooking().booking.payment.gstFare}` },
                  { label: 'Toll', value: `₹${RequestBookingService.getRequestBooking().booking.payment.toll}` },
                  { label: 'Parking', value: `₹${RequestBookingService.getRequestBooking().booking.payment.parking}` },
                ].map((item) => (
                  <React.Fragment key={item.label}>
                    <Grid item xs={7}>
                      <Typography
                        align="left"
                        fontWeight={item.isEstimated ? 'bold' : 'normal'}
                        sx={item.isEstimated ? { fontSize: '1.5rem' } : {}}
                      >
                        {item.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        align="left"
                        sx={{ color: "black", fontWeight: item.isEstimated ? 'bold' : 'normal', fontSize: item.isEstimated ? '1.5rem' : 'inherit' }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Divider sx={{ mb: 1 }} />

          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleBack}


            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              color: 'white',
              m: 3
            }}

          >Back</Button>

          <Button variant="contained" color="primary"
            onClick={handleNext}
            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark'
              },
              color: 'white',
              m: 3
            }}
          >
            Next
          </Button>
        </Grid>
      </div>

    </Container>

  );
};

export default NewBookingForm3;