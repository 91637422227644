import { gql } from '@apollo/client';


export const VALIDATE_USER = gql`query ValidateUser($phone: String!, $mpin: String!) {
  validateUser(phone: $phone, mpin: $mpin)  {
    userId
    phone
    authToken
    created
    message
  }
}
`;
export const SEND_OTP = gql`
query SendOtp($phone: String!) {
  sendOtp(phone: $phone) {
    message
    status
  }
}
`
export const GET_CARS = gql`query GetCars {
  getCars {
    _id
    carModel
    carNumber
    carType
    status
  }
}
`;

export const GET_DRIVERS = gql`query GetDrivers {
getDrivers {
  _id
  userId
  name
  phone
  status
}
}
`;


export const GET_BOOKINGS_BY_USERID = gql`
query GetBookingsByUserId($userId: ID!) {
  getBookingsByUserId(userId: $userId) {
     _id
    userId
    name
    bookingId
    phone
    createdDate
    bookingDate
    fromCity
    toCity
    fromAddress
    toAddress
    returnRide
    totalDistance
    carId
    driverId
    ride {
      startTime
      endTime
      rideOtp
      customerRating
      driverRating
      commentByCustomer
      commentByDriver
    }
     payment {
      paymentId
      paymentType
      baseFare
      gstFare
      toll
      parking
      waitingCharges
      extraKmCharges
      totalFare
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
      created
      status
    }
    partnerId
    notification {
      confirmationMsg
      cabDetailsMsg
      driverMsg
    }
    status
   
  }

}`

export const GET_INVOICE_BY_BOOKINGID = gql`
query GetInvoiceByBookingId($bookingId: String!) {
  getInvoiceByBookingId(bookingId: $bookingId) {
    invoiceNumber
    userId
    name
    bookingId
    gstNumber
    billingAddress
    rideStart
    rideEnd
    phone
    fromAddress
    toAddress
    car
    driver
    payment {
      baseFare
      gstFare
      toll
      parking
      totalFare
      waitingCharges
      extraKmCharges
      extraKms
      waitingTime
      carrierCharges
      extraPickorDrop
      extraPickorDropCharges
      discount
    }
    dateIssued
    status
  }
}
`
export const GET_STATIC_DATA = gql`
query GetStaticData {
  getStaticData {
    TermsAndCondition
    RideInstructions
    ImageUrls {
      uri
      title
      content
    }
    BydUrls {
      uri
      title
      content
    }
  }
}
`
