import React, { useState } from 'react';
import { Button, Container, Box, Typography, Grid, TextField, MenuItem, CircularProgress, Slide, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Card, CardContent, CardMedia, } from '@mui/material';
import { DirectionsRenderer, useLoadScript } from '@react-google-maps/api';

import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SourceDestination from '../components/booking/common/SourceDestination'
import BYDInfoWithFare from '../components/booking/common/BYDInfoWithFare'
import CountryCodePicker from '../components/booking/common/CountryCodePicker'
import { sendOtp } from '../components/services/userService'
import { validateUser } from '../components/services/userService';
import { AuthDetails } from '../components/models/AuthDetails';
import { AppContext } from '../components/services/AppContext'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { RequestBookingService } from "../components/services/RequestBookingService"
import { TransitionProps } from '@mui/material/transitions';
import CardImageRight from '../components/booking/common/CardImageRight'
import TermsAndCondition from '../components/booking/components/items/TermsAndConditions'
const libraries: ("places")[] = ["places"];



interface Form2Props {
  formValues: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement }, // Ensure children is a ReactElement
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewBookingForm2: React.FC<Form2Props> = ({ formValues, handleInputChange, handleNext, handleBack }) => {


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', // Use environment variable for API key
    libraries: libraries
  });

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fareDetails = [
    { name: 'Estimated Fare', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.totalFare}` },
    { name: 'Base Fare', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.baseFare}` },
    { name: "Extra Km Charges " + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraKms}` + ' x ₹38)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.extraKmCharges}` },
    { name: 'Extra Pickup/Drop ' + "( " + `${RequestBookingService.getRequestBooking().booking.payment.extraPickorDrop}` + ' x ₹200)', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.extraPickorDropCharges}` },
    { name: 'Carrier Charges', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.carrierCharges}` },
    { name: 'GST ( 5 % )', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.gstFare}` },
    { name: 'Toll', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.toll}` },
    { name: 'Parking', fare: `₹${RequestBookingService.getRequestBooking().booking.payment.parking}` },
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    handleInputChange(event)
    AppContext.travelDate = event.target.value
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value)
    handleInputChange(event)
    AppContext.travelTime = event.target.value
  };

  const generateTimeOptions = () => {
    const times = [];
    const formatTime = (hour: number, minute: number) => {
      return ` ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        times.push(formatTime(hour, minute));
      }
    }
    return times;
  };


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleNextClicked = () => {
    if (!formValues.bookingDate || !formValues.bookingTime || !formValues.numberOfBigBags || !formValues.numberOfPassengers) {
      // alert("Please Enter all required fields")
      setSnackbarMessage("Please make sure to fill in all required fields before proceeding.4");
      setSnackbarOpen(true);
      return
    }

    handleNext()

  }


  if (!isLoaded) return <div>Loading...</div>;
  // / bgcolor: '#f9f9f9'/
  return (
    <div>
      {/* <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
      </Snackbar> */}


      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
          <SourceDestination source={formValues.source} destination={formValues.destination} />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            Total Fare (BYD E6) : ₹{RequestBookingService.getRequestBooking().booking.payment.totalFare}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Including Expressway toll, gst, Airport parking & Extra kms
          </Typography>
        </CardContent>
        <Button size="small" onClick={handleClickOpen} sx={{
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark'
          },
          color: 'white',
          m: 3
        }} >Fare Details</Button>
      </Card>

      <Grid item xs={12} sm={12} lg={12}>
        <TextField
          type="date"
          name="bookingDate"
          label="Booking Date"
          fullWidth
          value={AppContext.travelDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            min: new Date().toISOString().split('T')[0], // today onwards
          }}
          variant="outlined"
          required
          sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
        />
      </Grid>

      <Grid item xs={12} sm={12} textAlign="left">
        <TextField
          select
          name="bookingTime"
          label="Booking Time"
          fullWidth
          value={AppContext.travelTime}
          onChange={handleTimeChange}
          variant="outlined"
          required
          sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}
        >
          {generateTimeOptions().map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="left">
        <TextField
          select
          label="Number Of Passengers"
          fullWidth
          name="numberOfPassengers"
          value={formValues.numberOfPassengers.toString()}
          onChange={handleInputChange}
          required
          //type="number"
          sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}

        >

          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="left">
        <TextField
          select
          label="Number Of Big Bags"
          fullWidth
          name="numberOfBigBags"
          value={formValues.numberOfBigBags.toString()}
          onChange={handleInputChange}
          required
          //type="number"
          sx={{ bgcolor: 'white', borderRadius: 1, mt: 2 }}

        >
          <MenuItem value="0">0</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
        </TextField>
      </Grid>


      <Box position="relative" width="100%">
        <Snackbar open={snackbarOpen} autoHideDuration={1000} onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Center it horizontally
          sx={{
            '& .MuiSnackbarContent-root': {
              maxWidth: '400px', // Adjust the max width
              width: '100%', // Ensure it takes full width up to max width
            },
            position: 'absolute',
            bottom: '60px', // Adjust based on your layout
            left: '50%',
            transform: 'translateX(-50%)', // Center horizontally
          }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
        </Snackbar>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={5}>
            <Button variant="contained" color="primary" onClick={handleBack}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                },
                color: 'white',
                m: 3
              }}
            >Back
          </Button>
          </Grid>
          <Grid item xs={6} sm={0}>
            <Button variant="contained" color="primary" onClick={handleNextClicked}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                },
                color: 'white',
                m: 3
              }}
            >Review  
          </Button>
          </Grid>
        </Grid>
      </Box>
      <br></br>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { width: '80%' } // Set to your desired width
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <span style={{ fontSize: '22px' }}>Estimated Fare</span>
          </div>
        </DialogTitle>
        <DialogContent>

          <CardImageRight header={'Luxury SUV+'} text={'Electric BYD E6'} imageSource={require('../images/BYD1.png')} ></CardImageRight>

          <Grid container spacing={1} justifyContent="center" alignItems="center">


            {fareDetails.map((detail, index) => (
              <>
                <Grid item xs={9} >
                  <span key={index} style={{
                    fontSize: detail.name === 'Estimated Fare' ? '16x' : '14px',
                    fontWeight: detail.name === 'Estimated Fare' ? 'bold' : 'normal',
                  }}>{detail.name}</span>
                </Grid>
                <Grid item xs={3} >
                  <span key={index} style={{
                    fontSize: detail.name === 'Estimated Fare' ? '16px' : '14px',
                    fontWeight: detail.name === 'Estimated Fare' ? 'bold' : 'normal',
                  }}>{detail.fare}</span>
                </Grid>
              </>
            ))}
          </Grid>

          {/* <TermsAndCondition></TermsAndCondition> */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" sx={{
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.dark'
            },
            color: 'white',
            m: 3
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default NewBookingForm2;
