import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

// Define the Footer component using TypeScript
const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: '#25d366',
        color: 'white',
      }}
    >
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body1" align="center">
            © 2024 Green Rides Pune
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
