import React, { useState, useContext, useEffect } from 'react';
import { TextField, MenuItem, Grid, Box, Container } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { AppContext } from '../../services/AppContext';


type CountryPickerProps = {
    phoneText: string;
    phoneVerified: boolean
}

const CountryCodePicker: React.FC<CountryPickerProps> = ({ phoneText, phoneVerified }: CountryPickerProps) => {
    const [selectedCode, setSelectedCode] = useState<string>('91');
    const [phone, setPhone] = useState<string>(phoneText);
    //  const { updatePhone } = useContext(AppContext);

    useEffect(() => {
        AppContext.phone = `(+${selectedCode})${phone}`
    }, [selectedCode, phone,]);

    const handlePhoneChange = (value: string) => {
        const code = value.substring(1, value.length); // Extract code from value
        setSelectedCode(value);
        console.log("Selected Code: " + value); // Log
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        console.log("Phone: " + event.target.value);
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={3} sm={2} md={1}>
                <PhoneInput
                    country={selectedCode.toLowerCase()}
                    value={selectedCode}
                    countryCodeEditable={false}
                    onChange={handlePhoneChange}

                    containerStyle={{
                        width: '90px',
                        margin: '9px auto',
                        border: '1px solid #ccc',
                        borderRadius: '2px',
                    }}
                    inputStyle={{
                        width: '100%',
                        height: '54px',
                        textAlign: 'right',
                        paddingLeft: '10px',
                        paddingRight: '5px',
                        fontSize: '16px',
                        borderRadius: '1px',
                        border: 'none',
                    }}
                    buttonStyle={{
                        borderRadius: '2px 0 0 2px',
                        backgroundColor: '#f1f1f1',
                    }}
                    dropdownStyle={{
                        borderRadius: '1px',
                    }}
                />
            </Grid>
            <Grid item xs={9} sm={10} md={11}>
                <TextField
                    label="Your WhatsApp Number"
                    variant="outlined"
                    value={phone}
                    disabled={phoneVerified}
                    fullWidth
                    onChange={handlePhoneInputChange}
                    sx={{ bgcolor: 'white', borderRadius: 1, mt: 1, mb: 0 }}
                />
            </Grid>
        </Grid>
    );
};

export default CountryCodePicker;
