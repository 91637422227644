import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, FormControlLabel, MenuItem, Divider, Alert } from '@mui/material';
import { GoogleMap, Autocomplete, DirectionsRenderer, useLoadScript } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { RequestBookingService } from "../components/services/RequestBookingService"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CardImageRight from '../components/booking/common/CardImageRight'
import NewBookingForm2 from './NewBookingForm2'; // Adjust the path as needed
import NewBookingForm3 from './NewBookingForm3'; // Adjust the path as needed
import NewBookingForm4 from './NewBookingForm4';
import Snackbar from '@mui/material/Snackbar';

const googleApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const libraries: ("places")[] = ["places"];



interface FormValues {
  name: string;
  phone: string;
  distance: string;
  source: string;
  destination: string;
  bookingDate: string;
  bookingTime: '';
  returnRide: boolean,
  numberOfPassengers: number,
  numberOfBigBags: number,
}

const NewBookingPage: React.FC = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApi, // Use environment variable for API key
    libraries: libraries
  });

  const webUrl = process.env.REACT_APP_URL;

  const videoUrl = `${webUrl}/images/ec3.mp4`;

  const navigate = useNavigate();
  const [booking, setBooking] = useState(RequestBookingService.getRequestBooking())
  const [currentStep, setCurrentStep] = useState(0);
  const [source, setSource] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [sourceAutocomplete, setSourceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [destinationAutocomplete, setDestinationAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [isReturnRide, setIsReturnRide] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  console.log(`process.env.NODE_ENV ${JSON.stringify(process.env)}`);
  console.log(`process.env.REACT_APP_BACKEND_URL ${process.env.REACT_APP_BACKEND_URL}`);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    phone: '',
    distance: "0",
    source: '',
    destination: '',
    bookingDate: '',
    bookingTime: '',
    returnRide: false,
    numberOfPassengers: 0,
    numberOfBigBags: 0
  });


  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [name]: name === 'distance' || name === 'fare' || name === 'tax' || name === 'gst' ? parseFloat(value) : value,
  //   });
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };



  const handleSourcePlaceChanged = () => {
    const place = sourceAutocomplete?.getPlace();
    if (place) {
      const newSource = place.formatted_address || place.name || '';
      setSource(newSource);
      setFormValues({ ...formValues, source: newSource })
      RequestBookingService.setSource(newSource)
      // Calculate distance whenever source changes
      if (destination) {
        calculateDistance(newSource, destination);
      }
    }
  };

  const handleDestinationPlaceChanged = async () => {
    const place = destinationAutocomplete?.getPlace();
    if (place) {
      const newDestination = place.formatted_address || place.name || '';
      setDestination(newDestination);
      setFormValues({ ...formValues, destination: newDestination })
      RequestBookingService.setDestination(newDestination)
      // Calculate distance whenever destination changes
      if (source) {
        await calculateDistance(source, newDestination);
      }
    }
  };

  const handleNext = async () => {
    if (!source || !destination) {
      // alert("Please Enter all required fields")
      setSnackbarMessage("Please make sure to fill in all required fields before proceeding.1");
      setSnackbarOpen(true);
      return
    }
    if (currentStep === 1 && (!formValues.bookingDate || !formValues.bookingTime)) {
      // alert("Please Enter all required fields")
      setSnackbarMessage("Please make sure to fill in all required fields before proceeding.2");
      setSnackbarOpen(true);
      return
    }
    if (currentStep === 3 && (!formValues.name)) {
      // alert("Please Enter all required fields")
      setSnackbarMessage("Please make sure to fill in all required fields before proceeding.3");
      setSnackbarOpen(true);
      return
    }

   await RequestBookingService.calculateFare()
    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };




  const calculateDistance = async (origin: string, destination: string) => {
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING
    });

    if (results.routes[0]) {
      const route = results.routes[0];
      const distanceInMeters = route.legs[0].distance?.value || 0; // value in meters
      let localdistance = Math.ceil((distanceInMeters / 1000))
      const distanceInKm = localdistance; // Convert to kilometers/

      RequestBookingService.setDistance(localdistance);
      setDistance(localdistance.toString());
      setFormValues(prev => ({
        ...prev,
        distance: distanceInKm.toString(), // Convert distance to string

      }));
      setDuration(route.legs[0].duration?.text || '');
    }
  };


  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTime(event.target.value);
  };

  const generateTimeOptions = () => {
    const times = [];
    const formatTime = (hour: number, minute: number) => {
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        times.push(formatTime(hour, minute));
      }
    }
    return times;
  };

  const handleRideOptionClick = (option: boolean) => {
    setIsReturnRide(option);
    setFormValues({ ...formValues, returnRide: option })
    if (booking) {
      booking.booking.returnRide = option
    }

  };


  const handleSubmit = () => {


  }


  if (!isLoaded) return <div>Loading...</div>;

  return (

    <div>
      {currentStep === 0 && (<>
        <video width="100%" autoPlay loop muted controls={false}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
          Book Your Ride
        </Typography>
        <Typography
          variant="body2">
          Pune to Mumbai / Mumbai - Pune Ride
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box component="form" noValidate autoComplete="off" >
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={12} >
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6} sm={6} md={6}>
                  <Box onClick={() => handleRideOptionClick(false)} >
                    <Typography variant="h6" sx={{ color: !isReturnRide ? '#0D47A1' : '#000' }}>One Way Mumbai Drop/Pickup</Typography>
                    {!isReturnRide ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                        <CheckCircleOutlineIcon color="action" />
                      )}
                    <Typography variant="body2" sx={{ color: 'text.secondary', }}>One way Pune/Mumbai Drop/Pickup</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Box onClick={() => handleRideOptionClick(true)} >
                    <Typography variant="h6" sx={{ color: isReturnRide ? '#0D47A1' : '#000' }}>Pune to Mumbai & Return</Typography>
                    {isReturnRide ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                        <CheckCircleOutlineIcon color="action" />
                      )}
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>Pune to Mumbai Ride & return same day</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Grid item xs={12} sm={12} lg={12}>
              <Autocomplete
                onLoad={(autocomplete) => setSourceAutocomplete(autocomplete)}
                onPlaceChanged={handleSourcePlaceChanged}
              >
                <TextField
                  label="Source"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  sx={{ bgcolor: 'white', borderRadius: 1, mb: 3 }}
                />
              </Autocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={12} lg={12}>
              <Autocomplete
                onLoad={(autocomplete) => setDestinationAutocomplete(autocomplete)}
                onPlaceChanged={handleDestinationPlaceChanged}
              >
                <TextField
                  label="Destination"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                  sx={{ bgcolor: 'white', borderRadius: 1 }}
                />
              </Autocomplete>
            </Grid>
          </Grid>
          <br></br>

          <Grid container spacing={3} justifyContent="center" >
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Box position="relative" width="100%">
                <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Center it horizontally
                  sx={{
                    '& .MuiSnackbarContent-root': {
                      maxWidth: '400px', // Adjust the max width
                      width: '100%', // Ensure it takes full width up to max width
                    },
                    position: 'absolute',
                    bottom: '60px', // Adjust based on your layout
                    left: '50%',
                    transform: 'translateX(-50%)', // Center horizontally
                  }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="error">{snackbarMessage}</Alert>
                </Snackbar>
                <Button variant="outlined" color="primary" onClick={handleNext}
                  sx={{
                    bgcolor: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.dark'
                    },
                    color: 'white',

                    width: '100%',
                    margin: '10 10 10 10',
                  }}

                >
                  NEXT
                </Button>
              </Box>
            </Grid>
          </Grid>
          <br></br>
        </Box>
      </>

      )}

      {currentStep === 1 && (
        <>
          <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
            Book Your Ride
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <NewBookingForm2
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      )}
      {currentStep === 2 && (
        <>
          <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
            Review Your Booking Request
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <NewBookingForm3
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      )}
      {currentStep === 3 && (
        <>
          <Typography variant="h5" align="center" gutterBottom sx={{ color: 'green' }}>
            Your Booking Request
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <NewBookingForm4
            formValues={formValues}
            handleInputChange={handleInputChange}
            handleBack={handleBack}
          // handleNext={handleNext}
          />
        </>

      )}

    </div>
  );
};

export default NewBookingPage;
