
import moment from 'moment';

export const isToday = ((date: string): boolean => {
    const today = new Date();
    const dt = stringToDate(date);
    return dt.getDate() === today.getDate() &&
        dt.getMonth() === today.getMonth() &&
        dt.getFullYear() === today.getFullYear();
});

export const getTimeFromDate = ((date: string): string => {
    const dt = stringToDate(date);
    const hours = String(dt.getHours()).padStart(2, '0');
    const minutes = String(dt.getMinutes()).padStart(2, '0');
    const seconds = String(dt.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
});

export const stringToDate = ((dateString: string): Date => {
    return new Date(dateString);
});


export const dateFormat = ((date : string) : string => {
    const dt = stringToDate(date);
    let displayDate = (isToday(date) === true) ? 'TODAY' : moment(dt).format('ddd DD, MMM');
    let diaplaytime = moment(date).format('HH:mm') + ' IST' //+ ' [' + moment(date).format('hh:mm A') + ' IST]';
    return `${displayDate} ${diaplaytime}`;
});