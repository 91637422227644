// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
    display: flex;
    align-items: center; /* Center items vertically */
    padding: 16px; /* Add padding around the card */
    background-color: #FFFFFF; /* White background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
    margin: 8px 0; /* Vertical margin */
    width: 95%; /* Full width of the page */
    height: 100px; /* Set a fixed height for the card */
}

.text-container {
    flex: 1 1; /* Allow text to take remaining space */
    padding-right: 16px; /* Space between text and image */
}

.header {
    margin: 0; /* Remove default margin */
    font-size: 24px; /* Adjust as needed */
}

.text {
    margin: 0; /* Remove default margin */
    font-size: 16px; /* Adjust as needed */
}

.image-container {
    flex: 0.7 1; /* Adjust size for the image container */
}

.image {
    width: 160px; /* Set width for the image */
    height: 100%; /* Match image height to card height */
    object-fit: cover; /* Maintain aspect ratio */
}
`, "",{"version":3,"sources":["webpack://./src/components/booking/CSSStyle/CardImageRight.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,4BAA4B;IACjD,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB,EAAE,qBAAqB;IAChD,kBAAkB,EAAE,oBAAoB;IACxC,wCAAwC,EAAE,kBAAkB;IAC5D,aAAa,EAAE,oBAAoB;IACnC,UAAU,EAAE,2BAA2B;IACvC,aAAa,EAAE,oCAAoC;AACvD;;AAEA;IACI,SAAO,EAAE,uCAAuC;IAChD,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,SAAS,EAAE,0BAA0B;IACrC,eAAe,EAAE,qBAAqB;AAC1C;;AAEA;IACI,SAAS,EAAE,0BAA0B;IACrC,eAAe,EAAE,qBAAqB;AAC1C;;AAEA;IACI,WAAS,EAAE,wCAAwC;AACvD;;AAEA;IACI,YAAY,EAAE,4BAA4B;IAC1C,YAAY,EAAE,sCAAsC;IACpD,iBAAiB,EAAE,0BAA0B;AACjD","sourcesContent":[".card-container {\r\n    display: flex;\r\n    align-items: center; /* Center items vertically */\r\n    padding: 16px; /* Add padding around the card */\r\n    background-color: #FFFFFF; /* White background */\r\n    border-radius: 8px; /* Rounded corners */\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */\r\n    margin: 8px 0; /* Vertical margin */\r\n    width: 95%; /* Full width of the page */\r\n    height: 100px; /* Set a fixed height for the card */\r\n}\r\n\r\n.text-container {\r\n    flex: 1; /* Allow text to take remaining space */\r\n    padding-right: 16px; /* Space between text and image */\r\n}\r\n\r\n.header {\r\n    margin: 0; /* Remove default margin */\r\n    font-size: 24px; /* Adjust as needed */\r\n}\r\n\r\n.text {\r\n    margin: 0; /* Remove default margin */\r\n    font-size: 16px; /* Adjust as needed */\r\n}\r\n\r\n.image-container {\r\n    flex: 0.7; /* Adjust size for the image container */\r\n}\r\n\r\n.image {\r\n    width: 160px; /* Set width for the image */\r\n    height: 100%; /* Match image height to card height */\r\n    object-fit: cover; /* Maintain aspect ratio */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
