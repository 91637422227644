// src/pages/About.tsx
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

const TermsAndConditions: React.FC = () => {

  const webUrl = process.env.REACT_APP_URL;

  return (
    <div>
      <video width="100%" autoPlay loop muted>
        <source src={`${webUrl}images/ec3.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography textAlign="left" variant="h6" gutterBottom>
        Terms & Conditions
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="No of passengers : Max 4" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Luggage Boot Space: 23kg x 5 large bags + 2 handbags max for 4 Adult Passengers. 23kg x 7 large bags + 2 handbags max for 2/3 Adult Passengers" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Airport Waiting Charges : 1 hour waiting is free in fare. Additional Waiting time will be charged ₹300/30 minutes" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Home Pickup Waiting Charges : 15 minutes waiting is free. Additional Waiting time will be charged ₹200/30 minutes" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="As per Goverment and RTO rules, no carrier is allowed on top of the vehicle for safety reasons. As there is no carrier on vehicles; all luggage has to be managed in the available car boot space only" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Cancellation Charges : Need to pay 25% charges if cancelled 24 hrs before, 50% before 4 hrs and 100% after that" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Car Charging : BYD E6 has range up to 520 Kms. We will charge car for 30 minutes if it's required." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Food Mall Break : Max 30 minutes." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Additional Breaks or Visits : Additional breaks or visits are not allowed. If you need it, please tell us in advance." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Refund Policy : The refund would be credited to the customer within seven (7) working days." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="In case of offline/COD booking handover booking cash amount to vehicle driver." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="Keep eye on your valuable assets during entering and exit from vehicle, We are not responsible for your valuable assets." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="No damage claim allowed during an accident" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon style={{ fontSize: '10px' }} />
          </ListItemIcon>
          <ListItemText primary="If you have complaints, please contact office immediately (8600 57 2727) after finishing tour complaints will not be entertained." />
        </ListItem>

      </List>
    </div>
  );
};

export default TermsAndConditions;
