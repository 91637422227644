import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/logo.jpg';
import WhatsAppButtonShort from './WhatsAppButtonShort';


const StickyHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 1000,
          backgroundColor: 'white', //#3f51b5
          color: 'green',
          paddingLeft: '4px',
          paddingRight: '4px',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'left' }} component={Link} to="/">
            <img src={logo} alt="Logo" style={{ marginRight: '10px', height: '40px' }} />
            
          </Box>
          <Box >
            <WhatsAppButtonShort></WhatsAppButtonShort>
            &nbsp;&nbsp;            
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose} component={Link} to="/book-your-green-ride">Book Your Green Ride</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/termsandconditions">Terms & Conditions</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/contactus">Contact Us</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/privacypolicy">Privacy Policy</MenuItem>
              {/* <MenuItem onClick={handleMenuClose} component={Link} to="/login">Login</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/usercompletedrides">Your Past Rides</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/usernextrides">Your Upcoming Rides</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/invoice/NR2EAU">Invoice</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/quote">Quote</MenuItem>
              <MenuItem onClick={handleMenuClose} component={Link} to="/exportpdf/NR2EAU">Pdfexport</MenuItem> */}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Simulate scrollable content
      <Box sx={{ height: '2000px', padding: '20px', backgroundColor: '#f0f0f0' }}>
        <Typography variant="body1">
          Scroll down to see the sticky header in action. This is an example of scrollable content.
          The header will remain fixed at the top while the rest of the content scrolls.
        </Typography>
      </Box> */}
    </>
  );
};

export default StickyHeader;
