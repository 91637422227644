import React from 'react';
import { RequestBookingService } from '../../services/RequestBookingService'
import './../CSSStyle/SourceDestination.css'; // Assuming you have CSS for styling
import LocationOnIcon from '@mui/icons-material/LocationOn';


interface SourceDestinationProps {
  source:string;
  destination: string
  }
  
  const SourceDestination: React.FC<SourceDestinationProps> = ({ source, destination}) => {
    

    return (
        <div>
            {source && (
                <div className="row">
                    <div className="icon-container">
                        <LocationOnIcon style={{ color: 'green' , alignItems:'center' }} />
                    </div>
                    <div className="text-container">
                        <p className="description">{source}</p>
                    </div>
                </div>
            )}
            {destination && (
                <div className="row">
                    <div className="icon-container">
                        <LocationOnIcon style={{ color: 'red' }} />
                    </div>
                    <div className="text-container">
                        <p className="description">{destination}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SourceDestination;
