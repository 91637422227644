import { Booking } from "../models/booking";
import { AuthDetails } from "../models/AuthDetails";




export class AppContext {

    static phone = '';

    static booking: Booking;
    static bookings: Booking[];
    static selectedUserBookings: Booking[];
    static id: number
    static emptyObjectId: string = "000000000000000000000000";

    static travelDate: string = '';
    static travelTime: string = '';

    static AddUserAndBookRide: boolean = true;

    static secreteKey: string = "3fa6aefc52e9c6d1898db9b4d6b51c8e3b77b98b2086d6523e4c89124f86a117";
    static loggedUserId: string
    static loggedAuthToken: string

    static clearAll() {


    }

    static getBooking(): Booking {
        return this.booking;
    }

    static setBooking(booking: Booking) {
        this.booking = booking;
    }

    static getBookings(): Booking[] {
        return this.bookings;
    }

    static setBookings(bookings: Booking[]) {
        this.bookings = bookings;
    }

    static getBookingId(): number {
        return this.id;
    }

    static setBookingId(id: number) {
        this.id = id;
    }






}


